export const dropboxEnums = {
    data() {
        return {
            fetchingDropboxState: false,
            isDropboxLinked: false
        }
    },
    computed: {
        redirectURI() {
            const callbackURL = this.requestURI
            const clientID = '5xnkt28kw0sv2nw'
            return `https://www.dropbox.com/oauth2/authorize?client_id=${clientID}&redirect_uri=${callbackURL}&response_type=code&token_access_type=offline`
        },
        requestURI() {
            return location.toString().replace(location.search, '')
        }
    },
    methods: {
        checkDropboxStatus() {
            this.fetchingDropboxState = true
            this.$axios
                .get('/tokens/dropbox')
                .then(r => {
                    if (r && r.data) {
                        this.isDropboxLinked = true
                    }
                })
                .catch(e => {
                    console.log(e)
                    this.isDropboxLinked = false
                })
                .finally(() => {
                    this.fetchingDropboxState = false
                })
            if (this.$route.query && this.$route.query.code) {
                this.fetchingDropboxState = true
                this.$axios
                    .post('/tokens/dropbox', { code: this.$route.query.code, request_uri: this.requestURI })
                    .then(r => {
                        this.isDropboxLinked = true
                    })
                    .catch(e => {
                        this.$store.dispatch('showSnackBar', { snackBarText: 'Linking Dropbox failed. Please try again later.', snackBarIcon: 'error' })
                    })
                    .finally(() => {
                        this.fetchingDropboxState = false
                    })
            }
        }
    }
}
