<template>
    <v-card flat elevation="3" class="ma-1">
        <v-list three-line color="transparent">
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="primary lighten-1" dark v-if="!feature.error && !feature.disabledReason">
                        {{ feature.enabled ? 'check_circle' : 'circle' }}
                    </v-icon>
                    <v-icon class="error lighten-1" dark v-else>error</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{ feature.label }}</v-list-item-title>
                    <v-list-item-subtitle>{{ feature.description }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-card-text v-if="!feature.enabled && feature.disabledReason && !feature.error" class="warning--text pt-0">
            Your current plan does not allow using {{ feature.label }} feature. Please reach out to {{ whoEnablesFeature[feature.disabledReason] }} if you
            require this feature.
        </v-card-text>
        <v-card-text v-if="feature.error" class="error--text pt-0">There was an error while getting the status of {{ feature.label }} feature.</v-card-text>
        <v-card-actions v-if="!feature.error && feature.canEnable">
            <v-spacer />
            <v-btn
                text
                color="primary"
                :loading="feature.loading"
                :disabled="!feature.canEnable"
                @click="feature.fn(!feature.enabled)"
                v-if="!feature.enabled && !feature.disabledReason">
                Enable
            </v-btn>
            <template v-if="feature.enabled">
                <v-btn text color="secondary" :loading="feature.loading" v-if="isOrgManager && !enableOnly" @click="feature.fn(!feature.enabled)">
                    Disable
                </v-btn>
                <v-btn text disabled v-if="enableOnly">Enabled</v-btn>
            </template>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'orgFeatureStatus',
    props: {
        feature: {},
        enableOnly: {
            type: Boolean,
            default: false
        },
        level: {
            type: String,
            default: 'organization'
        }
    },
    data() {
        return {
            whoEnablesFeature: {
                TURNED_OFF_RESOURCE_POOL: `this ${this.level}'s resource pool manager`,
                TURNED_OFF_ORG: "this space's organization manager",
                DISABLED: 'our support'
            }
        }
    },
    computed: {
        ...mapGetters('orgStore', ['isOrgManager'])
    }
}
</script>
