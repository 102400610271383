import { areDatesInOrder } from '@/utils'
import { round } from 'lodash'

export const usageAndResources = {
    data() {
        return {
            relationActionTypes: {
                REMOVE: 'Delete',
                MAP: 'Map'
            },
            relationActionLevel: {
                ORGANIZATION: 'Organization',
                SPACE: 'Space'
            },
            contractTypes: [
                { type: 'Mixed', code: 0 },
                { type: 'Education', code: 10 },
                { type: 'Research', code: 20 }
            ],
            nuvolosPackageTypes: {
                EDUCATION: 0,
                MIXED: 1,
                RESEARCH: 2
            },
            metrics: {
                APP_USAGE: 'apps',
                HPC_USAGE: 'hpc',
                USERS_STATISTICS: 'users',
                INVITATION_STATISTICS: 'invitations'
            },
            resourceTypes: [
                { label: 'File system', value: '0', description: 'Credits spent on file system storage', color: '#41a6f6', icon: 'mdi-file-outline' },
                { label: 'Database storage', value: '10', description: 'Credits spent on database storage', color: '#257179', icon: 'mdi-database' },
                { label: 'Database computation', value: '20', description: 'Credits spent on database queries', color: '#b13e53', icon: 'mdi-memory' },
                {
                    label: 'Credit-based computation',
                    value: '30',
                    description: 'Credits spent on compute resources',
                    color: '#ef7d57',
                    icon: 'mdi-animation-outline'
                },
                { label: 'Batch compute', value: '40', description: 'Credits spent on processing time', color: '#ffcd75' },
                { label: 'Consulting', value: '50', description: 'Credits spent on consulting', color: '#a7f070', icon: 'mdi-handshake-outline' },
                { label: 'Application', value: '60', description: 'Credits spent on applications', color: '#38b764' },
                { label: 'Video', value: '70', description: 'Credits spent on video library', color: '#38b764' },
                { label: 'Account transfer', value: '80', description: 'Credit transfer between accounts', color: '#38b764', icon: 'payments' },
                { label: 'Large File Storage', value: '90', description: 'Credits spent on large file storage', color: '#9857a4', icon: 'mdi-server' }
            ],
            performingOperation: false,
            resourcePoolResources: [],
            dateRangeOptionsRanges: [],
            dataDateRange: [],
            selectedDateRangeOption: 'Current month',
            dateRangeOptions: ['Current month', 'Previous month', 'Custom range'],
            doughnutChartColors: ['#009933', '#ff3300', '#ffcc00', '#3366ff', 'ff66cc', '#990099', '#666699', '#cccccc', '#000000'],
            rules: {
                required: value => !!value || value === 0 || 'Required.',
                isFloat: value => value === null || Number(value) === parseFloat(value) || 'Must be a number',
                isInteger: value => parseInt(value) === Number(value) || 'Must be an integer',
                rangeRequired: value => (value && value.split('to').length === 2) || 'Select start and end date',
                dateOrder: value => (value && value.split('to').length === 2 && areDatesInOrder(value)) || 'Start date should be smaller than end date',
                isNegative: value => value < 0 || 'Must be negative',
                isPositive: value => value >= 0 || 'Must be positive'
            }
        }
    },
    methods: {
        roundNumber(number, precision = 1) {
            return round(number, precision)
        },
        updateDateRange() {
            this.dataDateRange = this.dateRangeOptionsRanges[this.selectedDateRangeOption]
        },
        setDateRanges() {
            const dateRangeOptions = {}
            const end = new Date()
            const endString = end.toISOString().slice(0, 10)
            const currentMonth = new Date(Date.UTC(end.getFullYear(), end.getMonth(), 1))
            dateRangeOptions['Current month'] = [currentMonth.toISOString().slice(0, 10), endString]

            const previousMonth = new Date()
            previousMonth.setMonth(previousMonth.getMonth() - 1)
            const previousMonthStart = new Date(previousMonth.getFullYear(), previousMonth.getMonth(), 2)
            const previousMonthEnd = new Date(previousMonth.getFullYear(), previousMonth.getMonth() + 1, 1)
            const previousMonthStartString = previousMonthStart.toISOString().slice(0, 10)
            const previousMonthEndString = previousMonthEnd.toISOString().slice(0, 10)
            dateRangeOptions['Previous month'] = [previousMonthStartString, previousMonthEndString]

            const lastThirtyDays = new Date()
            lastThirtyDays.setDate(lastThirtyDays.getDate() - 30)
            dateRangeOptions['Custom range'] = [lastThirtyDays.toISOString().slice(0, 10), endString]
            this.dataDateRange = dateRangeOptions['Current month']
            this.dateRangeOptionsRanges = dateRangeOptions
        },
        getResourceTypeByCode(resourceCode) {
            const resource = this.resourceTypes.find(resourceType => resourceType.value === resourceCode.toString())
            return resource ? resource.label : 'Unknown resource type'
        },
        fetchResourcePoolResources(rpid) {
            this.$store.dispatch('userStore/setResourcePoolResourcesFetching', true)
            this.$axios
                .get(`/resource/${rpid}/resources`)
                .then(response => {
                    this.resourcePoolResources = response.data
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to fetch resource pool resources.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.$store.dispatch('userStore/setResourcePoolResourcesFetching', false)
                })
        },
        mapResourcePool(resourcePoolIdentifier, nuvolosIdentifier, relationActionLevel, resourceTypeToMap = null) {
            this.performingOperation = true
            const baseURL = `/resource/${resourcePoolIdentifier}/map`
            const apiURL =
                relationActionLevel === this.relationActionLevel.ORGANIZATION
                    ? `${baseURL}/${nuvolosIdentifier}`
                    : resourceTypeToMap !== null
                    ? `${baseURL}/space/${nuvolosIdentifier}/type/${resourceTypeToMap}`
                    : `${baseURL}/space/${nuvolosIdentifier}`
            this.$axios
                .put(apiURL)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Resource pool relation successfully created!',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                })
                .catch(error => {
                    if (error.response && error.response.data && error.response.data.code === 'map_exists') {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Resource map already exists!',
                            snackBarTimeout: 5000,
                            snackBarIcon: 'error'
                        })
                    } else if (error.response && error.response.data && error.response.data.code === 'map_downgrades_current_map') {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'The map currently exists for entire space - cannot map entire space or just one resource from same space again',
                            snackBarTimeout: 5000,
                            snackBarIcon: 'error'
                        })
                    } else {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Failed to map resource pool to the selected space.',
                            snackBarTimeout: 5000,
                            snackBarIcon: 'error'
                        })
                    }
                })
                .finally(() => {
                    this.performingOperation = false
                })
        },
        deleteResourceMapping(resourcePoolIdentifier, nuvolosIdentifier, relationActionLevel, resourceTypeToMap = null) {
            this.performingOperation = true
            const baseURL = `/resource/${resourcePoolIdentifier}/unmap`
            const apiURL =
                relationActionLevel === this.relationActionLevel.ORGANIZATION
                    ? `${baseURL}/${nuvolosIdentifier}`
                    : resourceTypeToMap !== null
                    ? `${baseURL}/space/${nuvolosIdentifier}/type/${resourceTypeToMap}`
                    : `${baseURL}/space/${nuvolosIdentifier}`
            this.$axios
                .delete(apiURL)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Resource pool relation successfully removed!',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'error'
                    })
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to remove resource pool relation.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.performingOperation = false
                })
        }
    }
}
